var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "travio-center-container",
    {
      attrs: {
        "grid-width": "1/2",
        pageTitle: "Supplier Details - " + _vm.form.supplierName,
      },
    },
    [
      _c(
        "vx-card",
        [
          _c(
            "vs-tabs",
            { staticClass: "tabs-shadow-none" },
            [
              _c(
                "vs-tab",
                {
                  attrs: {
                    "icon-pack": "feather",
                    icon: "icon-settings",
                    label: "General Info",
                  },
                },
                [
                  _c("vs-input", {
                    staticClass: "w-full mt-4",
                    attrs: { label: "Supplier Name*", disabled: "" },
                    model: {
                      value: _vm.form.supplierName,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "supplierName", $$v)
                      },
                      expression: "form.supplierName",
                    },
                  }),
                  _c(
                    "div",
                    { staticClass: "mt-4" },
                    [
                      _c("label", { staticClass: "text-sm" }, [
                        _vm._v("Product Type*"),
                      ]),
                      _c("v-select", {
                        attrs: {
                          value: _vm.selectedProductType,
                          "append-to-body": "",
                          options: _vm.productTypeOptions,
                          disabled: "",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "mt-4" },
                    [
                      _c("label", { staticClass: "text-sm" }, [
                        _vm._v("API Type*"),
                      ]),
                      _c("v-select", {
                        attrs: {
                          value: _vm.selectedApiType,
                          "append-to-body": "",
                          options: _vm.apiTypeOptions,
                          disabled: "",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("vs-input", {
                    staticClass: "w-full mt-4",
                    attrs: { label: "Endpoint URL", disabled: "" },
                    model: {
                      value: _vm.form.endpointUrl,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "endpointUrl", $$v)
                      },
                      expression: "form.endpointUrl",
                    },
                  }),
                  _c("vs-input", {
                    staticClass: "w-full mt-4",
                    attrs: { label: "Docs URL", disabled: "" },
                    model: {
                      value: _vm.form.docsUrl,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "docsUrl", $$v)
                      },
                      expression: "form.docsUrl",
                    },
                  }),
                  _c("vs-input", {
                    staticClass: "w-full mt-4",
                    attrs: { label: "Status URL", disabled: "" },
                    model: {
                      value: _vm.form.statusUrl,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "statusUrl", $$v)
                      },
                      expression: "form.statusUrl",
                    },
                  }),
                ],
                1
              ),
              _c(
                "vs-tab",
                {
                  attrs: {
                    "icon-pack": "feather",
                    icon: "icon-users",
                    label: "Contacts",
                  },
                },
                [
                  _c("vs-input", {
                    staticClass: "w-full mt-4",
                    attrs: { label: "Tech Contact", disabled: "" },
                    model: {
                      value: _vm.form.techContact,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "techContact", $$v)
                      },
                      expression: "form.techContact",
                    },
                  }),
                  _c("vs-input", {
                    staticClass: "w-full mt-4",
                    attrs: { label: "Account Contact", disabled: "" },
                    model: {
                      value: _vm.form.accountContact,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "accountContact", $$v)
                      },
                      expression: "form.accountContact",
                    },
                  }),
                  _c("vs-input", {
                    staticClass: "w-full mt-4",
                    attrs: { label: "Post Book Contact", disabled: "" },
                    model: {
                      value: _vm.form.postBookContact,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "postBookContact", $$v)
                      },
                      expression: "form.postBookContact",
                    },
                  }),
                ],
                1
              ),
              _c(
                "vs-tab",
                {
                  attrs: {
                    "icon-pack": "feather",
                    icon: "icon-lock",
                    label: "Credentials",
                  },
                },
                [
                  _c("vs-input", {
                    staticClass: "w-full mt-4",
                    attrs: { label: "Username", disabled: "" },
                    model: {
                      value: _vm.form.username,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "username", $$v)
                      },
                      expression: "form.username",
                    },
                  }),
                  _c("vs-input", {
                    staticClass: "w-full mt-4",
                    attrs: {
                      type: "password",
                      label: "Password",
                      disabled: "",
                    },
                    model: {
                      value: _vm.form.password,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "password", $$v)
                      },
                      expression: "form.password",
                    },
                  }),
                ],
                1
              ),
              _c(
                "vs-tab",
                {
                  attrs: {
                    "icon-pack": "feather",
                    icon: "icon-code",
                    label: "Settings",
                  },
                },
                [
                  _c("supplier-settings", {
                    attrs: { supplierId: _vm.supplierId },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "flex flex-wrap justify-end" },
            [
              _c(
                "vs-button",
                {
                  staticClass: "mt-4 mr-4",
                  attrs: { type: "border", color: "danger" },
                  on: { click: _vm.handleCancel },
                },
                [_vm._v("Cancel")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }